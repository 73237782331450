<template>
  <b-sidebar
    id="add-new-unit-sidebar"
    :visible="isAddNewDepartementSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-departement-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Departement
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
            ref="refFormObserver"
            #default="{invalid}"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="addNewDepartement"
          @reset.prevent="resetForm"
        >
          <!-- Kota -->
          <validation-provider
            #default="validationContext"
            name="Departement Name"
            rules="required"
          >
            <b-form-group
              label="Departement Name"
              label-for="Departement Name"
            >
              <b-form-input
                id="DepartementName"
                v-model="DepartementName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Departement Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
                block
                :disabled="invalid && submitButtonDisabled"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { ref } from '@vue/composition-api'

import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import useDepartementsList from './useDepartementsList'

const DepartementName = ''
const DepartementCode = ''
const DepartementCity = ''
const DepartementAddress = ''
const DepartementPhone = ''
const DepartementFax = ''
const submitButtonDisabled = false
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewDepartementSidebarActive',
    event: 'update:is-add-new-unit-sidebar-active',
  },
  props: {
    isAddNewDepartementSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      submitButtonDisabled,
      required,
      alphaNum,
      DepartementName,
      DepartementCode,
      DepartementCity,
      DepartementAddress,
      DepartementPhone,
      DepartementFax,
    }
  },
  setup() {
    const blankDepartementData = {
      DepartementName: '',
      DepartementCode: '',
    }
    const unitData = ref(blankDepartementData)
    const resetunitData = () => {
      unitData.value = blankDepartementData
    }

    const onSubmit = () => {
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetunitData)

    return {
      DepartementName,
      DepartementCode,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  mounted() {
  },
  methods: {
    addNewDepartement() {
      this.submitButtonDisabled = true
      const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
        const payload = {
          departement_name: this.DepartementName,
          time: moment(),
          }
          axios
          .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_ADD_DEPARTEMENTS}`, payload, { headers })
          .then(response => {
            this.submitButtonDisabled = false
              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          if (response.data.success === true) {
                this.$root.$emit('refreshTable', 'refreshTable')
                this.isAddNewDepartementSidebarActive = false
                this.DepartementName = ""
                this.DepartementCode = ""
                // this.$parent.refDepartementListTable.refresh()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Your data has been inserted!',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: 'Something went wrong',
                  },
                })
              }
              }).catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: 'Something went wrong',
                  },
                })
              })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-unit-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
