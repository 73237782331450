<template>

  <div>

    <departement-list-add-new
      :is-add-new-departement-sidebar-active.sync="isAddNewDepartementSidebarActive"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />
    <departement-list-edit
      :is-edit-departement-sidebar-active.sync="isEditDepartementSidebarActive"
      :departement-id.sync="DepartementID"
      :departement-name.sync="DepartementName"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="isAddNewDepartementSidebarActive = true"
              >
                <span class="text-nowrap">Add Departement</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refDepartementListTable"
        class="position-relative"
        :items="fetchDepartements"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Departement Name -->
        <template #cell(DepartementName)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.departement_name }}</span>
          </div>
        </template>

        <!-- Column: Departement Code -->
        <template #cell(DepartementCode)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.departement_code }}</span>
          </div>
        </template>

        <!-- Column: Departement City -->
        <template #cell(DepartementCity)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.departement_city }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editDepartement(  data.item )">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteDepartement( data.item.departement_id )">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalDepartements"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import axios from 'axios'
import vSelect from 'vue-select'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
// import DepartementsListFilters from './DepartementsListFilters.vue'
import DepartementList from './DepartementList'
import DepartementModule from './DepartementModule'
import DepartementListAddNew from './DepartementAddNew.vue'
import DepartementListEdit from './DepartementEdit.vue'

const userRole = ''
const DepartementData = null
export default {
  components: {
    // DepartementsListFilters,
    DepartementListEdit,
    DepartementListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  mounted() {
    this.userRole = this.$cookies.get('UserRole')
    this.$root.$on('refreshTable', () => {
    this.$refs.refDepartementListTable.refresh()
  })
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = 'app-unit'

    // Register module
    if (!store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.registerModule(BRANCH_APP_STORE_MODULE_NAME, DepartementModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
    })

    const isAddNewDepartementSidebarActive = ref(false)
    const isEditDepartementSidebarActive = ref(false)
    const DepartementID = ref('')
    const DepartementName = ref('')
    const DepartementCode = ref('')
    const DepartementCity = ref('')
    const DepartementAddress = ref('')
    const DepartementPhone = ref('')
    const DepartementFax = ref('')
    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchDepartements,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalDepartements,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDepartementListTable,
      refetchData,

      // UI
      resolveDepartementRoleVariant,
      resolveDepartementRoleIcon,
      resolveDepartementStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = DepartementList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    return {

      // Sidebar
      DepartementID,
      DepartementName,
      DepartementCode,
      DepartementCity,
      DepartementAddress,
      DepartementPhone,
      DepartementFax,
      isEditDepartementSidebarActive,
      isAddNewDepartementSidebarActive,
      userRole,
      DepartementData,
      fetchDepartements,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalDepartements,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDepartementListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveDepartementRoleVariant,
      resolveDepartementRoleIcon,
      resolveDepartementStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    }
  },
  methods: {
      deleteDepartement(id) {
        const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DELETE_DEPARTEMENTS}/${id}`
          axios
              .get(url, { headers })
              .then(response => {
                console.log(response)
                if (response.data.success === true) {
                  this.$refs.refDepartementListTable.refresh()
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Congratulation',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Your data has been deleted!',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: 'Something went wrong',
                    },
                  })
                }
              })
              .catch(err => {
                  console.log(err)
              })
      },
      editDepartement(data) {
        const parsed = data
        this.DepartementID = parsed.departement_id.toString()
        this.DepartementName = parsed.departement_name
        this.isEditDepartementSidebarActive = true
      },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
